(<template>
  <post-accordion-section ref="qualificationSection"
                          :state="qualificationSelect"
                          :title="title"
                          :description="description"
                          :disabled="disabledFields"
                          class="qualification"
                          @focusin="openQualificationSectionHelper"
                          @statechanged="updatedQualificationSelectState">
    <template v-if="qualificationSelect">
      <sk-select :description="qualificationText"
                 :items-list="qualifications"
                 :default-value="qualificationText"
                 :preselected-value="qualification"
                 :marked-items="disabledQualifications"
                 :disabled="disabledFields"
                 class="qualification__select"
                 @csvaluechanged="changeQualification" />
      <qualification-processing v-if="showQualificationProcessingSection"
                                class="qualification__processing" />
    </template>
  </post-accordion-section>
</template>)

<script>
  import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';
  import PostAccordionSection from '@/components/booking_components/PostAccordionSection';
  import QualificationProcessingSection from '@/components/booking_components/parts/qualification_section/QualificationProcessingSection';

  export default {
    components: {
      'post-accordion-section': PostAccordionSection,
      'qualification-processing': QualificationProcessingSection
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters('PostingNotifierStore', ['showQualificationProcessingSection']),
      ...mapState('PostingStore/QualificationStore', {
        qualification: ({qualification}) => qualification,
        qualificationSelect: ({qualificationSelect}) => qualificationSelect,
        qualificationPresenceList: ({qualificationPresenceList}) => qualificationPresenceList
      }),
      ...mapState('PostingStore/LanguagesStore', {
        langTo: ({languageTo}) => languageTo,
        langFrom: ({languageFrom}) => languageFrom
      }),
      title() { return this.$gettext('Qualification level'); },
      description() { return this.$gettext('I need an interpreter with a specific qualification'); },
      paramsList() { return this.params || {}; },
      disabledFields() { return this.paramsList.disabledFields; },
      qualificationText() { return this.$gettext('Qualification level'); },
      languages() { return this.$store.state.selectsData.language || []; },
      langToName() { return this.languages.find((lang) => lang.id == this.langTo)?.name || ''; },
      qualificationsList() { return this.$store.getters.interpretationQualifications; },
      qualifications() {
        return this.qualificationsList.map((qualification) => this.getQualificationItemForSelect(qualification)) || [];
      },
      disabledQualifications() {
        return this.qualifications.filter((qualification) => qualification.disabled);
      },
      showAssignmentHelper() { return this.params.showAssignmentHelper; }
    },
    methods: {
      ...mapActions('PostingStore/QualificationStore', [
        'changeQualification',
        'changeQualificationSelectState'
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setPopoverReference',
        'setReferenceToBuyerInfoSection',
      ]),
      openQualificationSectionHelper() {
        if (this.showAssignmentHelper) {
          this.setPopoverReference(this.$refs.qualificationSection);
          this.openHelper('qualification');
        }
      },
      updatedQualificationSelectState(value) {
        if (!value) {
          this.setReferenceToBuyerInfoSection();
          this.openHelper('');
        }
        this.changeQualificationSelectState(value);
      },
      getQualificationItemForSelect(qualification) {
        const qualificationObj = {subName: '', disabled: false};

        if (qualification.isInTolkeregister) {
          // don't disable if lang to is not selected
          if (this.langTo) {
            if (this.langFrom == 3) {
              // don't disable while it waits for presence response
              if (Array.isArray(this.qualificationPresenceList)) {
                const qualificationPresence = this.qualificationPresenceList.find((presence) => presence.qualificationId == qualification.id);

                if (qualificationPresence) {
                  const template = this.$gettext('There are %{interpreterCount} %{languageName} interpreters with this category in the National Interpreter register');
                  qualificationObj.subName = this.$gettextInterpolate(template, {
                    interpreterCount: qualificationPresence.interpretersCount,
                    languageName: this.langToName
                  });
                  qualificationObj.disabled = false;
                } else {
                  qualificationObj.subName = this.$gettext('This category is not listed in the National Interpreter Register');
                  qualificationObj.disabled = true;
                }
              }
            } else {
              qualificationObj.subName = this.$gettext('This category is not listed in the National Interpreter Register');
              qualificationObj.disabled = true;
            }
          }
        } else {
          qualificationObj.subName = this.$gettext('Internal category');
          qualificationObj.disabled = false;
        }

        return {
          id: qualificationObj.disabled ? '' : qualification.id,
          name: `${qualification.name}<span class="sk-select__sub-value">${qualificationObj.subName}</span>`,
          disabled: qualificationObj.disabled
        };
      },
    }
  };
</script>

<style>
  .qualification .sk-select__list-item.is-marked {
    font-weight: normal;
    opacity: 0.5;
    cursor: not-allowed;
  }
</style>

<style scoped>
  .qualification__select {
    margin-top: 15px;
  }

  .qualification__processing {
    margin-top: 20px;
  }
</style>
