(<template>
  <post-inner-section ref="assignmentTypeSection"
                      :title="title"
                      :disabled="disabledFields"
                      :class="{'is-with-error': showErrors,
                               'assignment-type--disabled': disabledFields}"
                      class="assignment-type">
    <div v-if="showErrors"
         class="assignment-type__errors sk-input__errors-cont">
      <span v-for="(error, index) in assignmentTypeErrors"
            :key="index"
            v-html="`${index + 1}. ${error}`"></span>
    </div>
    <div v-if="showBookAnotherInterpreterNote"
         class="assignment-type__description assignment-type__errors">{{ bookAnotherInterpreterNote }}</div>
    <template v-else>
      <div class="assignment-type__radios-cont">
        <!-- use v-show to prevent ssr errors -->
        <label v-show="allowedTypeOfAssignment('phone')"
               class="assignment-type__radio-label"
               @focusin="openAssignmentTypeHelper('phone')">
          <input v-model="selectedType"
                 :checked="assignmentType == 'phone'"
                 type="radio"
                 name="jobType"
                 value="phone"
                 class="assignment-type-btn__input"
                 tabindex="-1"
                 @change="changeAssignmentType" />
          <button class="assignment-type__icon assignment-type-btn assignment-type-btn--phone-white"
                  @click.prevent.stop="catchAssignmentType('phone')"></button>
          <span class="assignment-type__radio-name">{{ $gettext('Phone') }}</span>
        </label>
        <!-- use v-show to prevent ssr errors -->
        <label v-show="allowedTypeOfAssignment('in_person') || allowedTypeOfAssignment('in-person')"
               class="assignment-type__radio-label"
               @focusin="openAssignmentTypeHelper('in_person')">
          <input v-model="selectedType"
                 :checked="assignmentType == 'in_person'"
                 :disabled="disabledFields"
                 type="radio"
                 tabindex="-1"
                 name="jobType"
                 value="in_person"
                 class="assignment-type-btn__input"
                 @change="changeAssignmentType" />
          <button class="assignment-type__icon assignment-type-btn assignment-type-btn--inperson-white"
                  @click.prevent.stop="catchAssignmentType('in_person')"></button>
          <span class="assignment-type__radio-name">{{ $gettext('In-person') }}</span>
        </label>
        <!-- use v-show to prevent ssr errors -->
        <label v-show="allowedTypeOfAssignment('video') && showTypesData"
               class="assignment-type__radio-label"
               @focusin="openAssignmentTypeHelper('video')">
          <input v-model="selectedType"
                 :checked="assignmentType == 'video'"
                 :disabled="disabledFields"
                 type="radio"
                 name="jobType"
                 tabindex="-1"
                 value="video"
                 class="assignment-type-btn__input"
                 @change="changeAssignmentType" />
          <button class="assignment-type__icon assignment-type-btn assignment-type-btn--video-white"
                  @click.prevent.stop="catchAssignmentType('video')"></button>
          <span class="assignment-type__radio-name">{{ $gettext('Video') }}</span>
        </label>
        <!-- use v-show to prevent ssr errors -->
        <label v-show="allowedTypeOfAssignment('videoroom') && canShowVideoroom"
               class="assignment-type__radio-label"
               @focusin="openAssignmentTypeHelper('videoroom')">
          <input v-model="selectedType"
                 :checked="assignmentType == 'videoroom'"
                 :disabled="disabledFields"
                 type="radio"
                 tabindex="-1"
                 name="jobType"
                 value="videoroom"
                 class="assignment-type-btn__input"
                 @change="changeAssignmentType" />
          <button class="assignment-type__icon assignment-type-btn assignment-type-btn--videoroom-white"
                  @click.prevent.stop="catchAssignmentType('videoroom')"></button>
          <span class="assignment-type__radio-name">{{ $gettext('Video room') }}</span>
        </label>
      </div>
      <div v-if="showTypesData"
           class="assignment-type__description">{{ optionDescription }}</div>
      <!-- use v-show to prevent ssr errors -->
      <video-form v-if="assignmentType === 'video'" />
      <in-person-form v-if="assignmentType === 'in_person'"
                      :disabled-address="disabledFields"
                      :is-open-page="isOpenPage"
                      class="assignment-type__in-person-form" />
      <session-type-processing v-if="showSessionTypeProcessingSection"
                               :is-video="assignmentType === 'video' ? true : false" />
      <same-location-suppliers-list-section v-if="showSameLocationSuppliersListSection"
                                            class="assignment-type__same-location" />
    </template>
  </post-inner-section>
</template>)

<script>
  import {mapGetters, mapState, mapMutations, mapActions} from 'vuex';
  import InPersonForm from '@/components/booking_components/parts/assignment_type_section/InPersonForm';
  import PostInnerSection from '@/components/booking_components/PostInnerSection';
  import SessionTypeProcessingSection from '@/components/booking_components/parts/assignment_type_section/SessionTypeProcessingSection';
  import VideoForm from '@/components/booking_components/parts/assignment_type_section/VideoForm';
  import SameLocationSuppliersListSection from '@/components/booking_components/parts/suppliers_list_section/SameLocationSuppliersListSection';

  export default {
    components: {
      'video-form': VideoForm,
      'post-inner-section': PostInnerSection,
      'in-person-form': InPersonForm,
      'session-type-processing': SessionTypeProcessingSection,
      'same-location-suppliers-list-section': SameLocationSuppliersListSection
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        selectedTypeData: 'phone'
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsNotInterpreter',
        'userIsManager'
      ]),
      ...mapState('PostingStore/DirectBookingStore', {
        directBookingSelected: ({directBookingSelected}) => directBookingSelected
      }),
      selectedType() {
        return this.assignmentType ? this.assignmentType : this.selectedTypeData;
      },
      ...mapGetters('PostingNotifierStore', [
        'showSessionTypeProcessingSection',
        'showSameLocationSuppliersListSection'
      ]),
      ...mapState('PostingStore/AssignmentTypeStore', {
        allowedAssignmentTypes: ({allowedAssignmentTypes}) => allowedAssignmentTypes,
        assignmentType: ({assignmentType}) => assignmentType,
        contactAddresses: ({contactAddresses}) => contactAddresses,
        assignmentTypeErrorName: ({errors}) => errors.assignmentType.name,
        assignmentTypeErrors: ({errors}) => errors.assignmentType.errors
      }),
      showErrors() {
        return this.assignmentTypeErrors && this.assignmentTypeErrors.length;
      },
      title() { return this.$gettext('Assignment type'); },
      isLoggedIn() { return this.$store.state.TTAuthStore.loggedIn; },
      showTypesData() { return this.isLoggedIn ? (this.userIsNotInterpreter || this.userIsManager) : true; },
      canShowVideoroom() { return this.isLoggedIn ? (this.userIsNotInterpreter || this.userIsManager) : true; },
      paramsList() { return this.params || {}; },
      optionDescription() { return this.paramsList.optionDescription || this.jobTypeText; },
      disabledFields() { return this.paramsList.disabledFields; },
      isOpenPage() { return this.paramsList.isOpenPage; },
      isDirectBookingPage() { return this.paramsList.isDirectBookingPage; },
      isInPersonJob() {
        return this.assignmentType === 'in_person';
      },
      jobTypeText() {
        if (this.assignmentType == 'phone') {
          return this.$gettext('When you receive assignment confirmation, it will contain the interpreter\'s phone number. Call the interpreter directly at the agreed time.');
        } else if (this.assignmentType == 'in_person') {
          return this.$gettext('Please provide the full address of the meeting location and any other details to help the interpreter find it');
        } else if (this.assignmentType == 'video') {
          return this.$gettext('You will receive an SMS / email with a direct link to the virtual meeting room when the interpreter is assigned. The meeting room opens 5 minutes before the start of the assignment.');
        } else if (this.assignmentType == 'videoroom') {
          return this.$gettext('This assignment is conducted in a private booth at our office over video. We will invite your selected interpreter to our office and ensure that your session is private.');
        }
      },
      showBookAnotherInterpreterNote() {
        return !this.allowedAssignmentTypes.length && this.isDirectBookingPage;
      },
      bookAnotherInterpreterNote() {
        return this.$gettext('This interpreter doesn\'t have any active assignment types. Please book another interpreter');
      },
      impersonateRequests() { return this.params.impersonateRequests || false; },
      headers() { return {AuthImpersonationUID: this.getOwnerUid}; },
      showAssignmentHelper() { return this.params.showAssignmentHelper; },
    },
    methods: {
      ...mapActions('PostingStore/AssignmentTypeStore', ['setAssignmentType']),
      ...mapMutations('PostingStore/AssignmentTypeStore', ['removeOneError']),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setPopoverReference',
      ]),
      openAssignmentTypeHelper(focusedType) {
        this.setPopoverReference(this.$refs.assignmentTypeSection);
        if (this.showAssignmentHelper && focusedType == 'phone') {
          this.openHelper('phoneAssignment');
        }
        if (this.showAssignmentHelper && focusedType == 'in_person') {
          this.openHelper('inPersonAssignment');
        }
        if (this.showAssignmentHelper && focusedType == 'video') {
          this.openHelper('videoAssignment');
        }
        if (this.showAssignmentHelper && focusedType == 'videoroom') {
          this.openHelper('videoRoomAssignment');
        }
      },
      allowedTypeOfAssignment(type) {
        return this.allowedAssignmentTypes.includes(type);
      },
      changeAssignmentType() {
        this.setAssignmentType(this.selectedType ? this.selectedType : '');
        if (this.isInPersonJob) this.$store.dispatch('PostingNotifierStore/refetchContactsInfo', {headers: this.impersonateRequests ? this.headers : {}});
        this.removeError();
      },
      catchAssignmentType(type) {
        this.setAssignmentType(type);
        this.changeAssignmentType();
      },
      removeError() {
        this.removeOneError('assignmentType');
        this.$store.commit('ErrorsStore/setError', {name: this.assignmentTypeErrorName, errors: []});
      }
    }
  };
</script>

<style>
  .assignment-type__radio-label input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
    opacity: 0;
  }

  .assignment-type__phone-multiple .link-label {
    padding-right: 5px;
    color: #ff5b24;
    color: var(--color-secondary);
    text-transform: uppercase;
  }

  .assignment-type__phone-multiple .link-text {
    text-decoration: underline;
  }
</style>

<style scoped>
  .assignment-type {
    text-align: center;
  }

  .assignment-type--disabled {
    opacity: 0.5;
  }

  .assignment-type__radios-cont {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 20px;
  }

  .assignment-type__radio-label {
    position: relative;
    display: inline-block;
    width: initial;
    min-width: 70px;
  }

  .assignment-type__radio-label:focus {
    outline: auto;
  }

  .assignment-type__same-location {
    margin: 30px 0 0 0;
    padding: 0;
  }

  .assignment-type__radio-name {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .assignment-type__icon {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    margin-bottom: 5px;
    cursor: pointer;
  }

  .assignment-type__description {
    width: 100%;
    padding: 0 10px;
    font-size: 12px;
  }

  .assignment-type__in-person-form {
    width: 100%;
  }

  .assignment-type__video-check {
    margin-top: 10px;
  }

  .assignment-type__phone-multiple {
    display: inline-block;
    margin-top: 10px;
    text-decoration: none;
  }

  .assignment-type__errors {
    width: 100%;
    padding-bottom: 15px;
    color: #f04;
    color: var(--color-error);
    font-size: 12px;
  }

  @media (max-width: 767px) {
    .assignment-type__radio-label {
      min-width: 60px;
      margin: 0 5px;
      font-size: 0.85em;
    }
  }
</style>
