(<template>
  <section class="assignment-type">
    <div class="assignment-type__wrapper"
         :class="[typeClass]">
      <p class="assignment-type__data">{{ data.typeText }}</p>
    </div>
  </section>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      typeClass() { return `assignment-type__wrapper--${this.data.type}`; }
    }
  };
</script>

<style scoped>
.assignment-type {
  display: block;
  width: 100%;
}

.assignment-type__wrapper {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 30px;
  padding: 10px;
  border-radius: 3px;
  background-color: #e6ebee;
}

.assignment-type__wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
  display: block;
  width: 30px;
  background-position: 50% 50%;
  background-size: 20px auto;
  background-repeat: no-repeat;
}

.assignment-type__wrapper--video::before {
  background-image: url(~@assets/imgs/assignment_types/posting/video.svg);
  background-image: var(--image-assignment-type-video-icon);
  background-position: 0 50%;
  background-size: 25px auto;
}

.assignment-type__wrapper--phone::before {
  background-image: url(~@assets/imgs/assignment_types/posting/phone.svg);
  background-image: var(--image-assignment-type-phone-icon);
  background-size: 22px auto;
}

.assignment-type__wrapper--in_person::before {
  background-image: url(~@assets/imgs/assignment_types/posting/inperson.svg);
  background-image: var(--image-assignment-type-in-person-icon);
  background-size: auto 20px;
}

.assignment-type__wrapper--videoroom::before {
  background-image: url(~@assets/imgs/assignment_types/posting/videoroom.svg);
  background-image: var(--image-assignment-type-videoroom-icon);
  background-size: auto 20px;
}

.assignment-type__data {
  margin: 0 auto;
  font-weight: bold;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .assignment-type {
    margin-bottom: 0;
  }

  .assignment-type__data {
    margin: 0 0 0 50px;
  }
}
</style>
