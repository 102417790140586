<script>
  import {mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';
  import PostingProcessingPrototype from '@/prototypes/PostingProcessingPrototype';

  export default {
    extends: PostingProcessingPrototype,
    data() {
      return {
        radiosItemName: 'qualificationProcessing'
      };
    },
    computed: {
      ...mapState('PostingStore/ProcessRequirementStore', {
        processing: ({qualificationProcessing}) => qualificationProcessing,
        processingErrors: ({errors}) => errors.qualificationProcessing.errors
      }),
      choices() {
        return [{
          text: helpers.getJobInfo.jobProcessRequirement(this, 'qualification_auto_update'),
          value: 'qualification_auto_update'
        }, {
          text: helpers.getJobInfo.jobProcessRequirement(this, 'qualification_cancel_not_found'),
          value: 'qualification_cancel_not_found'
        }];
      }
    },
    methods: {
      ...mapMutations('PostingStore/ProcessRequirementStore', ['setQualificationProcessing']),
      catchProcessing(value) {
        this.removeOneError({
          storeName: 'PostingStore',
          subStoreName: 'ProcessRequirementStore',
          fieldName: 'qualificationProcessing'
        });
        this.setQualificationProcessing(value);
      }
    }
  };
</script>
