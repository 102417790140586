(<template>
  <div ref="translationAttachments"
       class="project-attachments-section__wrapper"
       @focusin="openAttachmentsHelpers('document')">
    <h3 class="project-attachments-section__title">{{ documentText }}</h3>
    <div :class="{'is-with-error': showErrors || onError, 'is-dragover': isDragover}"
         class="project-attachments-section"
         @drop="dropHandler"
         @dragover="dragOverHandler"
         @dragleave="dragLeaveHandler">
      <p class="project-attachments-section__hint-text">{{ dragAndDropText }}</p>
      <sk-upload-file :id="'translationFile'"
                      :button-text="uploadText"
                      :reset="fileInputReset"
                      :accept="'*'"
                      class="project-attachments-section__button"
                      @changefileinput="catchAttachment" />
      <div v-for="(attachment, index) in attachments"
           :key="index"
           class="project-attachments-section__files">
        <div class="project-attachments-section__files-inner">
          <button type="button"
                  class="project-attachments-section__remove-btn"
                  @click="deleteAttachment({ index, id: attachment.id })"></button>
          <p class="project-attachments-section__file-name">{{ attachment.name }}</p>
          <p v-if="attachment.error"
             class="project-attachments-section__file-error">{{ attachment.error }}</p>
        </div>
        <attachment-password :attachment-index="index" />
      </div>
    </div>
    <div class="sk-input__error-cont">
      <p v-if="onError"
         class="project-attachments-section__file-error">{{ onError }}</p>
    </div>
    <div v-if="showErrors"
         class="sk-input__errors-cont">
      <span v-for="(error, index) in attachmentsErrors"
            :key="index"
            v-html="(attachmentsErrors.length === 1) ? error : `${index + 1}. ${error}`"></span>
    </div>
    <p class="project-attachments-section__instruction-text"
       v-html="uploadInstructionText"></p>
    <p class="project-attachments-section__limit-text">{{ maxFilesLimitText }}</p>
  </div>
</template>)

<script>
  import {mapState, mapGetters, mapMutations} from 'vuex';
  import AttachmentPassword from './AttachmentPassword';

  export default {
    components: {
      'attachment-password': AttachmentPassword,
    },
    props: {
      params: {
        type: Object,
        default: () => {
          return {};
        }
      }
    },
    data() {
      return {
        isDragover: false,
        onError: ''
      };
    },
    computed: {
      ...mapGetters('FeatureFlagsStore', ['enableFaceliftedDesign']),
      ...mapGetters('PostingStore/AttachmentsStore', ['attachments']),
      ...mapState('PostingStore/AttachmentsStore', {
        fileInputReset: (state) => state.fileInputReset,
        // errors
        attachmentsErrors: (state) => state.errors.attachments.errors || []
      }),
      uploadText() { return this.$pgettext('translation', 'Upload file'); },
      showErrors() { return this.attachmentsErrors.length; },
      dragAndDropText() { return this.enableFaceliftedDesign ? this.$pgettext('translation', 'Drag and drop files here, or choose files to upload') : this.$pgettext('translation', 'Drag and drop files here, or'); },
      documentText() { return this.$pgettext('translation', 'Document'); },
      uploadInstructionText() {
        const template = this.$pgettext('translation', 'Please share the document(s) for translation here. If you have any trouble uploading, please email us at <a class="sk-link sk-link--gray-bg" href="mailto:%{email}">%{email}</a>, and we will get in touch to help you with the file transfer.');
        return this.$gettextInterpolate(template, {email: 'support@salita.no'});
      },
      maxFilesLimitText() { this.$pgettext('translation', 'Max. file size: 128MB, Max. files: 100'); },
      showAssignmentHelper() { return this.params.showAssignmentHelper; }
    },
    watch: {
      attachments() {
        if (this.attachments.length <= 100 && !this.attachmentsErrors.length) {
          this.removeErrors();
          this.onError = '';
        }
      },
    },
    methods: {
      ...mapMutations('PostingStore/AttachmentsStore', [
        'setAttachments',
        'deleteAttachment',
        'setError',
        'removeErrors'
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setContext',
        'setPopoverReference',
      ]),
      openAttachmentsHelpers(content) {
        if (this.showAssignmentHelper) {
          this.setContext('translation');
          this.setPopoverReference(this.$refs.translationAttachments);
          this.openHelper(content);
        }
      },
      catchAttachment(target) {
        const targetFiles = target.files;
        this.removeErrors();
        this.validFiles(target);
        if (targetFiles && targetFiles.length) {
          this.setAttachments(target);
          this.openAttachmentsHelpers('passwordDocument');
        }
      },
      setIsDragOver(isDragover) {
        this.isDragover = isDragover;
      },
      validFiles(target) {
        if (target.files.length >= 100 || this.attachments.length >= 100) {
          this.onError = this.$pgettext('translation', 'Max. file size: 128MB, Max. files: 100');
          return;
        }
        this.onError = '';
      },
      dropHandler(event) {
        event.preventDefault();
        this.catchAttachment(event.dataTransfer);
        this.setIsDragOver(false);
      },
      dragOverHandler(event) {
        event.preventDefault();
        this.setIsDragOver(true);
      },
      dragLeaveHandler(event) {
        event.preventDefault();
        this.setIsDragOver(false);
      }
    }
  };
</script>

<style scoped>
.is-dragover {
  background-color: #ffe6dd91;
}

.is-with-error {
  margin-bottom: 10px;
  background-color: #ff004440;
}

.project-attachments-section__wrapper {
  width: 100%;
  margin: 10px 15px 0 15px;
  border-top: 1px solid #d3d5de;
}

#app.salita-facelifted .project-attachments-section__wrapper {
  margin: 0;
  border: 0;
}

#app.salita-facelifted >>> .hint-text--underline {
  text-decoration: underline;
}

#app.salita-facelifted .project-attachments-section__button {
  position: static;
}

#app.salita-facelifted .project-attachments-section__button >>> .sk-upload-file__label-btn {
  display: none;
}

#app.salita-facelifted .project-attachments-section__button >>> .sk-upload-file__left-icon {
  display: block;
  width: 40px;
  height: 40px;
  background-image: url(~@assets/imgs/undefined_imgs/upload_facelifted.svg);
}

#app.salita-facelifted .project-attachments-section__button >>> .sk-upload-file__file-input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  inset: 0;
}

.project-attachments-section {
  position: relative;
  width: 100%;
  padding: 20px;
  border: dotted 2px var(--color-secondary);
  border-radius: 10px;
  font-size: 12px;
  text-align: center;
}

#app.salita-facelifted .project-attachments-section {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  gap: 12px;
  align-items: center;
  padding: 24px;
  border: dashed 1px #dddfe7;
}

.project-attachments-section__title {
  margin: 15px 0;
}

.project-attachments-section__instruction-text {
  margin: 5px 0;
  font-size: 12px;
}

.project-attachments-section__limit-text {
  margin: 10px 0;
  color: #0000007f;
  font-size: 13px;
}

.project-attachments-section__hint-text {
  margin-bottom: 10px;
  font-size: 14px;
  text-align: center;
}

#app.salita-facelifted .project-attachments-section__hint-text {
  margin: 4px 0 0;
}

.project-attachments-section__button {
  display: inline-block;
}

.project-attachments-section__files {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.project-attachments-section__files-inner {
  display: flex;
  align-items: center;
}

.project-attachments-section__remove-btn {
  display: block;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  background-image: var(--image-trash-icon);
  background-position: 50% 50%;
  background-size: 12px auto;
  background-repeat: no-repeat;
}

.project-attachments-section__remove-btn:active {
  background-color: #e1d7eb;
}

.project-attachments-section__file-error {
  width: 100%;
  margin-top: 2px;
  padding-bottom: 10px;
  color: #f04;
  font-size: 12px;
}

.sk-input__errors-cont {
  margin-left: 20px;
}
</style>
