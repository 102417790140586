(<template>
  <div :class="{'is-with-error': showErrors}"
       class="processing">
    <div class="processing__radios-wrapper">
      <p class="processing__radio-description">{{ description }}</p>
      <div v-for="choice in choices"
           :key="choice.text"
           class="processing__radio">
        <sk-radio :item-name="radiosItemName"
                  :preselected-value="processing"
                  :item-value="choice.value"
                  :item-text="choice.text"
                  @radiochanged="catchProcessing" />
        <video-form v-if="choice.value === 'video' && isVideoAutoUpdate"
                    class="video-form" />
      </div>
    </div>
    <div v-if="showErrors"
         class="sk-input__errors-cont">
      <span v-for="(error, index) in processingErrors"
            :key="index"
            v-html="(processingErrors.length === 1) ? error : `${index + 1}. ${error}`"></span>
    </div>
  </div>
</template>)

<script>
  import {mapActions} from 'vuex';
  import VideoForm from '@/components/booking_components/parts/assignment_type_section/VideoForm';

  export default {
    components: {
      'video-form': VideoForm
    },
    computed: {
      showErrors() { return this.processingErrors && this.processingErrors.length; },
      isVideoAutoUpdate() {
        return this.sessionTypeProcessing === 'session_type_auto_update'
          && this.processing === 'video';
      }
    },
    methods: {
      ...mapActions('PostingStore', ['removeOneError'])
    }
  };
</script>

<style>
.video-form .video-form__radios-wrapper {
  padding: 10px 0;
}
</style>

<style scoped>
  .processing {
    text-align: center;
  }

  .processing__radios-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    font-size: 12px;
    text-align: left;
  }

  .processing__radio {
    width: 100%;
    margin-top: 8px;
  }

  .processing__radio:first-child {
    margin-top: 0;
  }

  .video-form {
    max-width: 580px;
    margin: 5px 30px 5px 20px;
    padding: 5px 13px;
    border-radius: 4px;
    background-color: white;
  }
</style>
