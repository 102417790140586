export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      qualification: '',
      qualificationSelect: false,
      qualificationPresenceList: ''
    },
    actions: {
      changeQualification({commit, dispatch}, qualificationId) {
        commit('setQualification', qualificationId);
        dispatch('PostingNotifierStore/qualificationChanged', null, {root: true});
      },
      changeQualificationSelectState({commit, dispatch}, value) {
        commit('setQualificationSelectState', value);
        commit('PostingStore/ProcessRequirementStore/setQualificationProcessing', '', {root: true});
        dispatch('changeQualification', '');
      },
      getQualificationsPresence({commit, dispatch}, id) {
        return new Promise((resolve, reject) => {
          fetchData.getQualificationsPresence(id).then((response) => {
            const data = response?.length ? response[0].qualifications : [];
            commit('setQualificationPresenceList', data);
            dispatch('resetQualification', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      resetQualification({state, rootState, dispatch}, qualificationPresenceList) {
        const qualifications = rootState.selectsData.qualification || [];
        const currentQualificationInRegister = qualifications?.find((qualification) => {
          return qualification.id == state.qualification;
        })?.isInTolkeregister;
        const qualificationPresence = qualificationPresenceList?.find((presence) => presence.qualificationId == state.qualification);

        if (currentQualificationInRegister && !qualificationPresence) {
          dispatch('changeQualification', '');
        }
      }
    },
    mutations: {
      setQualification(state, value) {
        state.qualification = value;
      },
      setQualificationSelectState(state, value) {
        state.qualificationSelect = value;
      },
      setQualificationPresenceList(state, data) {
        state.qualificationPresenceList = data;
      },
      clearStore(state) {
        state.qualification = '';
        state.qualificationSelect = false;
        state.qualificationPresenceList = '';
      }
    }
  };
};
