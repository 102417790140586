const ticketId = (context, id) => {
  return id ? context.$gettextInterpolate(context.$gettext('#ST-%{id}'), {id}) : '';
};

const ticketCategory = (context, category) => {
  switch (category) {
    case 'assignment':
      return context.$gettext('Assignment');
    case 'finance':
      return context.$gettext('Finance');
    case 'other':
      return context.$gettext('Other');
    default:
      return '';
  }
};

const ticketStatus = (context, status) => {
  switch (status) {
    case 'pending':
      return context.$gettext('Pending');
    case 'resolved':
      return context.$gettext('Resolved');
    case 'unresolved':
      return context.$gettext('Unresolved');
    default:
      return '';
  }
};

const ticketSubject = (context, subject) => {
  switch (subject) {
    case 'question':
      return context.$gettext('Question');
    case 'complaint':
      return context.$gettext('Complaint');
    case 'compliment':
      return context.$gettext('Compliment');
    default:
      return '';
  }
};

export default {
  ticketId,
  ticketStatus,
  ticketCategory,
  ticketSubject
};
