const invoiceFrequency = (context, invoiceFrequency) => {
  switch (invoiceFrequency) {
    case 'daily':
      return context.$gettext('Every day');
    case 'weekly':
      return context.$gettext('Every week');
    case 'fortnightly':
      return context.$gettext('Every 2 weeks');
    default:
      return '';
  }
};

const invoiceDeliveryMechanizm = (context, invoiceMethod) => {
  switch (invoiceMethod) {
    case 'ehf':
      return context.$gettext('EHF');
    case 'email':
      return context.$gettext('Email');
    default:
      return '';
  }
};

const invoiceGroupStrategy = (context, invoiceGroupStrategy) => {
  switch (invoiceGroupStrategy) {
    case 'per_job':
      return context.$gettext('Per job');
    case 'per_department':
      return context.$gettext('Per department');
    case 'per_customer':
      return context.$gettext('Per customer');
    case 'per_order_reference':
      return context.$gettext('Per order reference');
    case 'per_buyer_reference':
      return context.$gettext('Per buyer reference');
    case 'per_buyer_order_and_reference':
      return context.$gettext('Per buyer and order reference');
    case 'per_booker':
      return context.$gettext('Per booker');
    default:
      return '';
  }
};

export default {
  invoiceFrequency,
  invoiceDeliveryMechanizm,
  invoiceGroupStrategy
};
